import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {PhoneNumber} from "../create-account/phonenumber-model";
import {WindowService} from "../window.service";
import * as firebase from "firebase";
import {isNullOrUndefined} from "util";
import {FormControl} from "@angular/forms";
import {CountryCode, parsePhoneNumber} from "libphonenumber-js/max";
import {AsYouType, parsePhoneNumberFromString} from "libphonenumber-js";

@Component({
  selector: 'app-phone-verification',
  templateUrl: './phone-verification.component.html',
  styleUrls: ['./phone-verification.component.css']
})
export class PhoneVerificationComponent implements OnInit {

  position = new FormControl("right");

  windowRef: any;
  phoneNumber = new PhoneNumber()
  user: any;
  verificationCode: string;
  show: boolean;
  error: undefined;
  sendingTextVerification: boolean = false;
  confirmingResult: boolean = false;
  codeVerified: boolean = false;
  profile: any;
  errorMessage:string = undefined;
  data: any = {};
  asYouType:any;
  @Output() onPhoneVerificationUpdated: EventEmitter<any> = new EventEmitter<any>();

  constructor(private win: WindowService) {



    this.asYouType = new AsYouType('US')

    this.data.phone = '';
  }

  ngOnInit() {

    console.log("Ng on init called")
    this.windowRef = this.win.windowRef
    this.windowRef.recaptchaVerifier = new firebase.auth.RecaptchaVerifier('recaptcha-container', {
      'size': 'invisible',
      'callback': function(response) {
        // reCAPTCHA solved, allow signInWithPhoneNumber.
        this.sendLoginCode();
      }
    });


    this.windowRef.recaptchaVerifier.render()
    this.user = null;
    this.verificationCode = "";
    this.sendingTextVerification = false;
    this.confirmingResult  = false;
    this.codeVerified  = false;
    this.windowRef.confirmationResult = null;


  }

  sendLoginCode() {
    console.log("Sending login code")

    this.sendingTextVerification = true;
    const appVerifier = this.windowRef.recaptchaVerifier;

    const num = "+" + this.asYouType.countryCallingCode +  this.asYouType.nationalNumber


    this.errorMessage = undefined;
    firebase.auth().signInWithPhoneNumber(num, appVerifier)
      .then(result => {

        this.windowRef.confirmationResult = result;
        this.sendingTextVerification = false;


      })
      .catch(error =>
      {
        this.sendingTextVerification = false;
        this.errorMessage = error.code;
        if(this.errorMessage = 'auth/too-many-requests')
        {
          this.errorMessage = "Too many requests";
        }
        console.log(error)
      });


  }

  verifyLoginCode() {
    this.confirmingResult = true;
    this.errorMessage = undefined;
    this.windowRef.confirmationResult
      .confirm(this.verificationCode)
      .then(result => {

        this.user = result.user;
        this.confirmingResult = false;
        this.codeVerified = true;
        //Update profile flag and close modal
        this.closeModal(true);
        this.errorMessage = undefined;

      })
      .catch(error => {
        this.confirmingResult = true;
        this.errorMessage = error.code;
        if(this.errorMessage === 'auth/invalid-verification-code')
        {
          this.errorMessage = "Invalid Verification Code";
        }
        if(this.errorMessage = 'auth/too-many-requests')
        {
          this.errorMessage = "Too many requests";
        }
        this.confirmingResult = false
      });
  }



  keytab(event, maxLength) {
    console.log(maxLength); // 2 or 4
    let nextInput = event.srcElement.nextElementSibling.nextElementSibling; // get the sibling element

    var target = event.target || event.srcElement;
    var id = target.id
    console.log(id.maxlength); // prints undefined

    if (nextInput == null)  // check the maxLength from here
      return;
    else {
      if (event.target.value.length === maxLength) {
        nextInput.focus();   // focus if not null

      }
    }
  }

  closeModal(verified) {
    this.onPhoneVerificationUpdated.emit(verified);

  }

  formatPhone(input)
  {
    this.asYouType = new AsYouType('US')
    this.data.phone =  this.asYouType.input(this.data.phone);
  }


}
