import {Component, ElementRef, HostListener, OnInit, ViewChild} from '@angular/core';
import {IpAddressService} from "../ip-address.service";
import {UrlService} from "../url.service";
import {HttpClient} from "@angular/common/http";
import {map} from "rxjs/internal/operators";
import {SuccessService} from "../success.service";
import {DeviceDetectorService} from "ngx-device-detector";
import {DeviceUtilService} from "../device-util.service";
import {IpAddress} from "../be-a-driver/ip.model";
import {ActivatedRoute, Router} from "@angular/router";
import {VersionUtilService} from "../version-util.service";
import {CookieService} from "ngx-cookie-service";
import {environment} from "../../environments/environment";
import {animate, state, style, transition, trigger} from "@angular/animations";
import {GoogleAnalyticsService} from "../google-analytics.service";
import {InspectletService} from "../inspectlet.service";


@Component({
  selector: 'app-coming-soon',
  templateUrl: './coming-soon.component.html',
  styleUrls: ['./coming-soon.component.css'],
  animations: [
    trigger('scrollAnimation', [
      state('show', style({
        opacity: 1,
        transform: "translateY(0)"
      })),
      state('hide',   style({
        opacity: 0,
        transform: "translateY(+50%)"
      })),
      transition('show => hide', animate('700ms ease-out')),
      transition('hide => show', animate('700ms ease-in'))
    ]),
    trigger('leftAnimateOnScroll', [
      state('show', style({
        opacity: 1,
        transform: "translateX(0)"
      })),
      state('hide',   style({
        opacity: 0,
        transform: "translateX(+100%)"
      })),
      transition('show => hide', animate('700ms ease-out')),
      transition('hide => show', animate('700ms ease-in'))
    ]),
    trigger('rightAnimateOnScroll', [
      state('show', style({
        opacity: 1,
        transform: "translateX(0)"
      })),
      state('hide',   style({
        opacity: 0,
        transform: "translateX(-100%)"
      })),
      transition('show => hide', animate('700ms ease-out')),
      transition('hide => show', animate('700ms ease-in'))
    ]),
    trigger('fadeInOut', [
      state('void', style({
        opacity: 0
      })),
      transition('void <=> *', animate(1000)),
    ]),
  ]
})
export class ComingSoonComponent implements OnInit {

  isReadOnlyWithEmail: boolean = true;
  isReadOnly: boolean = true;
  showStayTuned: boolean = false;
  lead: any = {};
  leadUrl: string;
  submitted: boolean = false;
  deviceInfo = null;
  version: string = 'X';
  campaign: string;
  shopUrl: string;
  testimonialState = 'hide'
  anonymousOrderState = 'hide'

  howItWorksOneState = 'hide'
  howItWorksTwoState = 'hide'
  howItWorksThreeState = 'hide'
  howItWorksFourState = 'hide'

  showPlayButton = true;
  videoSource = "https://drive.google.com/uc?export=download&id=1nG29H0ARqCZO-p7hxlnCm0mSyW6xCweQ";
  //elementArray = [this.testimonials,this.howItWorksOne,this.howItWorksTwo,this.howItWorksThree,this.howItWorksFour];

  constructor(private ipService: IpAddressService, private urlService: UrlService,
              private http: HttpClient, private successService: SuccessService, private deviceUtil: DeviceUtilService,
              private versionUtil: VersionUtilService, private elementRef: ElementRef,
              private inspectletService: InspectletService,private router: Router) {

    this.shopUrl = environment.shopUrl;

    this.version = versionUtil.version;
    this.campaign = versionUtil.campaign;
    this.leadUrl = urlService.getUrl("lead");
    this.ipService.getIpAddress().subscribe((data: IpAddress) => {
      this.lead.ipAddress = data.ip;
      console.log(data);
    });

    this.lead = deviceUtil.initLead();

  }

  ngOnInit() {



  }


  postLead() {
    console.log("Posting lead")
    this.lead.leadType = 'CUSTOMER';
    this.lead.campaign = this.campaign;
    var obs = this.http.post<any>(this.leadUrl, this.lead)
    obs.subscribe((response) => {
      if (response.status === 'OK') {
        this.submitted = true;

      }


    })

  }

  requiresReadOnly() {
    return this.deviceUtil.requiresReadOnly();
  }

  versionEquals(versions) {
    this.lead.version = this.versionUtil.version;
    return this.versionUtil.versionEquals(versions);
  }


  goToPricingFromTestimonial()
  {
    this.inspectletService.addTag("user clicked change my oil from testimonails")
    this.router.navigate(['/main/pricing-results']);

  }
}
