import {Injectable} from '@angular/core';
import {UrlService} from "./url.service";
import {HttpClient, HttpHeaders} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class MessageService {


  constructor(private urlService: UrlService, private http: HttpClient) {

  }




  getMessages()
  {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': 'Basic ' + btoa('AC3877c2432f69e8a518f4c2cb79290a7c:a71f8420f7478ad3fd0023654a92a588')
      })
    };

    let obs = this.http.get("https://avocado-serval-7638.twil.io/getMessages",httpOptions);
    return obs;
  }
}
