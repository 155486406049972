import { Injectable } from '@angular/core';
import {isNull, isNullOrUndefined} from "util";

declare let ga: Function;

@Injectable({
  providedIn: 'root'
})
export class GoogleAnalyticsService {

  currentProduct:any;

  constructor() { }

  public emitEvent(eventCategory: string,
                   eventAction: string,
                   eventLabel: string = null,
                   eventValue: number = null) {
    return ga('send', 'event', {
      eventCategory: eventCategory,
      eventLabel: eventLabel,
      eventAction: eventAction,
      eventValue: eventValue
    });
  }

  public productClicked()
  {
    ga('ec:addProduct', {
      'id': 'P12345',
      'name': 'Android Warhol T-Shirt',
      'category': 'Apparel',
      'brand': 'Google',
      'variant': 'black',
      'position': 1
    });
    ga('ec:setAction', 'click', {list: 'Search Results'});

    // Send click with an event, then send user to product page.
    ga('send', 'event', 'UX', 'click', 'Results', {
      hitCallback: function() {
        console.log("Done")
      }
    });

  }

  public sendTransaction(products,purchaseId)//sku,productName,productCategory,serviceProvider,variantTitle,price,quantity, purchaseId)
  {

    if(isNullOrUndefined(products) || products.length <= 0)
    {
      return;
    }

    console.log("Sending transaction to google analytics")
    //Add product for each product
    for(var i = 0; i < products.length;i++)
    {
      this.currentProduct = products[i];
      ga('ec:addProduct', {
        'id': this.currentProduct.variant.sku,
        'name': this.currentProduct.title,
        'category': this.currentProduct.category,
        'brand': this.currentProduct.brand,
        'variant': this.currentProduct.variant.title,
        'price': this.currentProduct.price,
        'quantity': 1
      });
    }


// Transaction level information is provided via an actionFieldObject.
    ga('ec:setAction', 'purchase', {
      'id': purchaseId,
      //'affiliation': 'Google Store - Online',
      //'revenue': '37.39',
      //'tax': '2.85',
      //'shipping': '5.34',
      //'coupon': 'SUMMER2013'    // User added a coupon at checkout.
    });

    ga('send', 'pageview');     // Send transaction data with initial pageview.

  }


  sendPageView()
  {
    ga('send', 'pageview');     // Send transaction data with initial pageview.

  }

  // Called when a product is added to a shopping cart.
  //
  addToCart(product) {
    this.currentProduct = product;
    ga('ec:addProduct', {
      'id': this.currentProduct.variant.sku,
      'name': this.currentProduct.title,
      'category': this.currentProduct.category,
      'brand': this.currentProduct.brand,
      'variant': this.currentProduct.variant.title,
      'price': this.currentProduct.price,
      'quantity': 1
    });
    ga('ec:setAction', 'add');
    ga('send', 'event', 'UX', 'click', 'add to cart');     // Send data using an event.
  }

  removeFromCart(product) {
    this.currentProduct = product;
    ga('ec:addProduct', {
      'id': this.currentProduct.variant.sku,
      'name': this.currentProduct.title,
      'category': this.currentProduct.category,
      'brand': this.currentProduct.brand,
      'variant': this.currentProduct.variant.title,
      'price': this.currentProduct.price,
      'quantity': 1
    });
    ga('ec:setAction', 'remove');
    ga('send', 'event', 'UX', 'click', 'remove from cart');     // Send data using an event.
  }

  //Takes a list of object->{store:x, item:x, hidden:x}
  addImpression(productList, searchList) {

    if(isNullOrUndefined(productList) || productList.length <= 0)
    {
      return;
    }

    for(var i = 0; i < productList.length;i++)
    {

      this.currentProduct = productList[i]
      console.log("Adding impressions")
      ga('ec:addImpression', {
        'id': this.currentProduct.variant.sku,
        'name': this.currentProduct.title,
        'category': this.currentProduct.category,
        'brand': this.currentProduct.brand,
        'variant': this.currentProduct.variant.title,
        'price': this.currentProduct.price,
        'quantity': 1,
        'list':searchList,
      });

      ga('send', 'pageview');


    }

  }

  addDetail(product)
  {
    this.currentProduct = product;
    console.log("Adding details")
    ga('ec:addProduct', {
      'id': this.currentProduct.variant.sku,
      'name': this.currentProduct.title,
      'category': this.currentProduct.category,
      'brand': this.currentProduct.brand,
      'variant': this.currentProduct.variant.title,
      'price': this.currentProduct.price,
      'quantity': 1,
    });
    ga('ec:setAction', 'detail');       // Detail action.
    ga('send', 'pageview');


  }

  refund(purchaseId)
  {

    if(isNullOrUndefined(purchaseId))
    {
      return;
    }
    console.log("refunding charge with id: " + purchaseId)
    ga('ec:addProduct', {
      'id': purchaseId,
    });

    ga('send', 'event', 'UX', 'click', 'cancel upcoming order');     // Send data using an event.

  }

  promotionImpression(id, name,creative,position)
  {
    ga('ec:addPromo', {               // Promo details provided in a promoFieldObject.
      'id': id,             // Promotion ID. Required (string).
      'name': name,          // Promotion name (string).
      'creative': creative,   // Creative (string).
      'position': position      // Position  (string).
    });

    ga('send', 'pageview');

  }

  promotionClicked(id, name,creative,position)
  {
    // Identify the promotion that was clicked.
    ga('ec:addPromo', {               // Promo details provided in a promoFieldObject.
      'id': id,             // Promotion ID. Required (string).
      'name': name,          // Promotion name (string).
      'creative': creative,   // Creative (string).
      'position': position      // Position  (string).
    });

// Send the promo_click action with an event.
    ga('ec:setAction', 'promo_click');
    ga('send', 'event', 'Internal Promotions', 'click', name);

  }

  completedCheckoutStep(stepNumber,option)
  {
    ga('ec:setAction','checkout', {
      'step': stepNumber,            // A value of 1 indicates this action is first checkout step.
      'option': option      // Used to specify additional info about a checkout stage, e.g. payment method.
    });
    ga('send', 'pageview');
  }

}
