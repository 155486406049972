import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-aws-cloudfront',
  templateUrl: './aws-cloudfront.component.html',
  styleUrls: ['./aws-cloudfront.component.css']
})
export class AwsCloudfrontComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
