import { Component, OnInit } from '@angular/core';
import {IpAddressService} from "../ip-address.service";
import {UrlService} from "../url.service";
import {HttpClient} from "@angular/common/http";
import {SuccessService} from "../success.service";
import {DeviceDetectorService} from "ngx-device-detector";
import {DeviceUtilService} from "../device-util.service";
import {IpAddress} from "./ip.model";
import {VersionUtilService} from "../version-util.service";

@Component({
  selector: 'app-be-a-driver',
  templateUrl: './be-a-driver.component.html',
  styleUrls: ['./be-a-driver.component.css']
})
export class BeADriverComponent implements OnInit {

  isReadOnly: boolean = true;
  lead: any = {};
  leadUrl: string;
  submitted: boolean = false;
  campaign : string;
  version: string;

  constructor(private ipService: IpAddressService, private urlService: UrlService,
              private http: HttpClient, private successService: SuccessService, private deviceUtil: DeviceUtilService,
  private versionUtil: VersionUtilService) {
    this.leadUrl = urlService.getUrl("lead");
    this.ipService.getIpAddress().subscribe((data: IpAddress) => {
      this.lead.ipAddress = data.ip;
      console.log(data);
    });
    this.lead = deviceUtil.initLead();
    this.version = this.versionUtil.version;
    this.campaign = this.versionUtil.campaign;
  }

  ngOnInit() {
  }

  toggleIsReadOnly() {

    this.isReadOnly = false;
  }
  postLead() {
    console.log("Posting lead")
    this.lead.leadType = 'DRIVER';
    this.lead.version = this.versionUtil.version;
    this.lead.campaign = this.campaign;
    this.lead.version = this.version;
    var obs = this.http.post<any>(this.leadUrl, this.lead)
    obs.subscribe((response) => {
      if (response.status === 'OK') {
        this.submitted = true;

      }


    })

  }

  requiresReadOnly()
  {
    return this.deviceUtil.requiresReadOnly();
  }
}
