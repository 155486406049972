import { Injectable } from '@angular/core';
import {DeviceDetectorService} from "ngx-device-detector";

@Injectable({
  providedIn: 'root'
})
export class DeviceUtilService {

  iphone : boolean = false;
  android: boolean = false;
  deviceInfo: any;
  userAgent : string;
  isMobile: boolean;
  isTablet: boolean;
  isDesktopDevice: boolean;


  constructor(private deviceService: DeviceDetectorService) {
    this.deviceInfo = this.deviceService.getDeviceInfo();
    this.isMobile = this.deviceService.isMobile();
    this.isTablet = this.deviceService.isTablet();
    this.isDesktopDevice = this.deviceService.isDesktop();
    if(this.deviceInfo !== null)
    {
      this.userAgent = this.deviceInfo.userAgent;
      if(this.userAgent.includes("iPhone") && this.isMobile)
      {
        console.log("This is an iphone")
        this.iphone = true;
      }
      if(this.userAgent.includes("Android") && this.isMobile)
      {
        console.log("This is an android device")
        this.android = true;
      }

    }
  }


  requiresReadOnly()
  {
    if(this.android)
    {
      return true;
    }
    else
    {
      return false;
    }
  }


  initLead()
  {
    var lead: any = {};
    lead.userAgent = this.deviceInfo.userAgent;
    lead.isMobile = this.isMobile;
    lead.isTablet = this.isTablet;
    lead.isDesktopDevice = this.isDesktopDevice;
    lead.userAgent = this.deviceInfo.userAgent;
    return lead;

  }
}
