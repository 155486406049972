import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {isNullOrUndefined} from "util";
import {AnonymousPricingService} from "../anonymous-pricing.service";
import {SanityService} from "../sanity.service";

@Component({
  selector: 'app-anonymous-pricing-widget',
  templateUrl: './anonymous-pricing-widget.component.html',
  styleUrls: ['./anonymous-pricing-widget.component.css']
})
export class AnonymousPricingWidgetComponent implements OnInit {

  vehicleMake:any;
  vehicleYear:any;
  vehicleModel:any;
  vehicle:any = {};
  form: FormGroup;
  stores:any;
  filteredStores:any;
  notAvailable:boolean;
  items:any;
  storeObs:any;
  data:any = {};
  submitting = false;

  @ViewChild("vehicleModel") myId: ElementRef;

  validation_messages = {
    'zipCode': [
      { type: 'required', message: 'Zip code is required. ' },
      { type: 'pattern', message: 'Enter a valid zip code. ' }

    ],
    'vehicleYear': [
      { type: 'required', message: 'Vehicle year is required. ' }
    ],
    'vehicleMake': [
      { type: 'required', message: 'Vehicle make is required. ' }
    ],
    'vehicleModel': [
      { type: 'required', message: 'Vehicle model is required. ' }
    ]
  }

  constructor( private elementRef:ElementRef,private fb: FormBuilder,
               private anonymousPricingService: AnonymousPricingService,
               private sanityService: SanityService) {
    this.form = this.fb.group({
      vehicleYear: new FormControl('', Validators.compose([
        Validators.required,
      ])),
      vehicleMake: new FormControl('', Validators.compose([
        Validators.required,
      ])),
      vehicleModel: new FormControl('', Validators.compose([
        Validators.required,
      ])),
      zipCode: new FormControl('', Validators.compose([
        Validators.required,
        Validators.pattern('^[0-9]{5}(?:-[0-9]{4})?$')
      ])),
    })

    this.form.get('vehicleMake').disable();
    this.form.get('vehicleModel').disable();


  }

  ngOnInit() {
    console.log("loading")
    this.vehicle = this.anonymousPricingService.vehicle;
    this.data.zipCode = this.anonymousPricingService.zipCode;

    var s = document.createElement("script");
    s.type = "text/javascript";
    s.src = "../../assets/js/carquery.js";
    this.elementRef.nativeElement.appendChild(s);

  }

  getStores()
  {
    this.submitting = true;
    this.notAvailable = false;
    this.anonymousPricingService.setZipCode(this.data.zipCode);
    this.anonymousPricingService.setVehicle(this.vehicle);
    this.items = [];
    //TODO
    //this.storeObs = this.sanityService.getServiceLocationsByZip(this.data.zipCode)

    this.storeObs = this.sanityService.getServiceLocationsByZip("54479d6a-9480-4496-b25f-49c84a18bc56")


    this.storeObs.subscribe(
      (data)=>
      {
        console.log("Got stores")
        this.stores = data.result;
        this.filterStores();
        if(isNullOrUndefined(this.filteredStores) || this.filteredStores.length == 0)
        {
          this.notAvailable = true;
          this.submitting = false;
          this.anonymousPricingService.setStores([]);
        }
        else
        {
          this.submitting = false;

        }




      }
    )

  }

  filterStores() {
    this.filteredStores = this.stores;
    console.log("filtering stores")
    if (isNullOrUndefined(this.stores) || this.stores.length == 0) {
      return;
    }

    if(isNullOrUndefined(this.vehicle))
    {
      return;
    }

    var filtered = [];
    for (var i = 0; i < this.stores.length; i++) {
      var vehicleExclusions = this.stores[i].vehicleExclusions;
      if(isNullOrUndefined(vehicleExclusions) || vehicleExclusions.length == 0)
      {
        filtered.push(this.stores[i])
      }
      else
      {
        var add = true;
        for(var j = 0; j < vehicleExclusions.length; j++)
        {
          var vehicleExclusion = vehicleExclusions[j];
          if(vehicleExclusion.make.toLowerCase() === this.vehicle.make.toLowerCase() &&
            vehicleExclusion.model.toLowerCase() === this.vehicle.model.toLowerCase())
          {
            add = false;
            break;
          }
        }

        if(add)
        {
          filtered.push(this.stores[i])

        }
      }

    }

    this.filteredStores = filtered;
    this.anonymousPricingService.setStores(this.filteredStores);
  }


  reset()
  {
    this.anonymousPricingService.setStores([]);
    this.vehicle = {};
    this.data={};
    this.form.reset();

  }

  yearChanged(make)
  {
    this.form.get('vehicleMake').enable();

  }

  makeChanged(model)
  {
    setTimeout(()=>{    //<<<---    using ()=> syntax
      console.log(this.myId.nativeElement.options[0].value)
      if(this.myId.nativeElement.options.length === 1)
      {
        this.vehicle.model = this.myId.nativeElement.options[0].value;
        this.form.controls['vehicleModel'].setValue(this.vehicle.model);

      }

    }, 500);

    this.form.get('vehicleModel').enable();

  }

  modelChanged(model)
  {
    //Doing this so we can set the model to the first value that is loaded

  }
}
