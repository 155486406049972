import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {ViewModule} from "./view/view.module";
import {TestService} from "./test.service";
import {HTTP_INTERCEPTORS, HttpClientModule} from "@angular/common/http";
import {AppRoutingModule} from "./app.routing.module";
import {PageNotFoundComponent} from "./page-not-found/page-not-found.component";
import {UrlService} from "./url.service";
import { AngularFireModule } from 'angularfire2';
import { AngularFirestoreModule } from 'angularfire2/firestore';
import { AngularFireAuthModule } from 'angularfire2/auth';
import { environment } from '../environments/environment';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  MatBadgeModule,
  MatDatepickerModule, MatDialogModule, MatIconModule, MatInputModule, MatOption, MatSelectModule, MatTooltipModule,
} from '@angular/material';
import {IpAddressService} from "./ip-address.service";
import {NgxStripeModule} from "ngx-stripe";
import {ErrorService} from "./error.service";
import {SuccessService} from "./success.service";
import {LocalStorageService} from "./local-storage.service";
import {LoaderService} from "./loader.service";
import {LoaderComponent} from "./loader/loader.component";
import {ComingSoonComponent} from "./coming-soon/coming-soon.component";
import {BeADriverComponent} from "./be-a-driver/be-a-driver.component";
import {DeviceDetectorModule} from "ngx-device-detector";
import {DeviceUtilService} from "./device-util.service";
import {BasicDialogComponent} from "./basic-dialog/basic-dialog.component";
import {VersionUtilService} from "./version-util.service";
import {LeadEnhancerComponent} from "./lead-enhancer/lead-enhancer.component";
import {CookieService} from "ngx-cookie-service";
import {PrivacyPolicyComponent} from "./privacy-policy/privacy-policy.component";
import {TermsOfServiceComponent} from "./terms-of-service/terms-of-service.component";
import {HamburgerModule} from "./hamburger/hamburger.module";
import {NavModule} from "./nav/nav.module";
import {FooterModule} from "./footer/footer.module";
import {MainComponent} from "./main/main.component";
import {HamburgerMenuComponent} from "./hamburger-menu/hamburger-menu.component";
import {AnonymousPricingComponent} from "./anonymous-pricing/anonymous-pricing.component";
import {DragScrollModule} from "ngx-drag-scroll/lib";
import {GenericCarouselComponent} from "./generic-carousel/generic-carousel.component";
import {AnonymousPricingResultsComponent} from "./anonymous-pricing-results/anonymous-pricing-results.component";
import {AnonymousPricingService} from "./anonymous-pricing.service";
import {GoogleAnalyticsService} from "./google-analytics.service";
import {ListItemsModule} from "./list-items/list-items.module";
import {AnonymousPricingWidgetComponent} from "./anonymous-pricing-widget/anonymous-pricing-widget.component";
import {LoadingModule} from "./loading/loading.module";
import {InspectletEventsTestComponent} from "./inspectlet-events-test/inspectlet-events-test.component";
import {InspectletService} from "./inspectlet.service";
import {MatMenuModule} from '@angular/material/menu';
import {FirebaseComponent} from './firebase/firebase.component';
import {TwilioComponent} from './twilio/twilio.component';
import {RedisComponent} from './redis/redis.component';
import {GoogleAnalyticsComponent} from './google-analytics/google-analytics.component';
import {CmsComponent} from './cms/cms.component';
import {ZendeskComponent} from './zendesk/zendesk.component';
import {ConfigurationManagementComponent} from './configuration-management/configuration-management.component';
import {InspectletComponent} from './inspectlet/inspectlet.component';
import {SendInBlueComponent} from './send-in-blue/send-in-blue.component';
import {DockerComponent} from './docker/docker.component';
import {CreateAccountComponent} from './create-account/create-account.component';
import {GenericModalComponent} from './generic-modal/generic-modal.component';
import {WindowService} from './window.service';
import {LoginComponent} from './login/login.component';
import {PasswordResetComponent} from './password-reset/password-reset.component';
import {PhoneVerificationComponent} from './phone-verification/phone-verification.component';
import {AngularComponent} from './angular/angular.component';
import {AwsS3Component} from './aws-s3/aws-s3.component';
import {ContinuousBuildDeployComponent} from './continuous-build-deploy/continuous-build-deploy.component';
import {AwsCloudfrontComponent} from './aws-cloudfront/aws-cloudfront.component';

@NgModule({
  declarations: [
    AppComponent,
    ComingSoonComponent,
    BeADriverComponent,
    LoaderComponent,
    BasicDialogComponent,
    PageNotFoundComponent,
    LeadEnhancerComponent,
    PrivacyPolicyComponent,
    TermsOfServiceComponent,
    MainComponent,
    HamburgerMenuComponent,
    AnonymousPricingComponent,
    GenericCarouselComponent,
    AnonymousPricingResultsComponent,
    AnonymousPricingWidgetComponent,
    InspectletEventsTestComponent,
    FirebaseComponent,
    TwilioComponent,
    RedisComponent,
    GoogleAnalyticsComponent,
    CmsComponent,
    ZendeskComponent,
    ConfigurationManagementComponent,
    InspectletComponent,
    SendInBlueComponent,
    DockerComponent,
    CreateAccountComponent,
    GenericModalComponent,
    LoginComponent,
    PasswordResetComponent,
    PhoneVerificationComponent,
    AngularComponent,
    AwsS3Component,
    ContinuousBuildDeployComponent,
    AwsCloudfrontComponent

  ],
  entryComponents: [BasicDialogComponent],

  imports: [
    BrowserModule,
    HttpClientModule,
    ViewModule,
    AppRoutingModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule, // imports firebase/firestore, only needed for database features
    AngularFireAuthModule,
    BrowserAnimationsModule,
    DeviceDetectorModule.forRoot(),
    MatDialogModule,
    HamburgerModule,
    NavModule,
    FooterModule,
    FormsModule,
    ReactiveFormsModule,
    MatInputModule,
    MatIconModule,
    DragScrollModule,
    MatBadgeModule,
    ListItemsModule,
    MatTooltipModule,
    MatIconModule,
    MatMenuModule,
    LoadingModule,
    MatSelectModule


  ],
  exports: [
    NavModule,
    MatDatepickerModule,
    ListItemsModule,
    MatDialogModule,
    MatIconModule,
    MatTooltipModule// imports firebase/auth, only needed for auth features
  ],
  providers: [
    AnonymousPricingService,
    UrlService,
    ErrorService,
    IpAddressService,
    LocalStorageService,
    LoaderService,
    DeviceUtilService,
    VersionUtilService,
    CookieService,
    GoogleAnalyticsService,
    InspectletService,
    WindowService,

  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
