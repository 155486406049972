import { Component, OnInit } from '@angular/core';
import {UrlService} from "../url.service";
import {Observable} from "rxjs/index";
import {catchError, map} from "rxjs/internal/operators";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {FirebaseAuthService} from "../firebase-auth.service";
import {Router} from "@angular/router";
import {ErrorService} from "../error.service";
import {SuccessService} from "../success.service";
import {LocalStorageService} from "../local-storage.service";
import {LoginService} from "../login.service";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {


  username: string = "";
  password: string = "";
  firebaseUid: string = "";
  show:boolean = false;
  visible = true;
  error = undefined;
  loggingIn = false;
  success = false;
  loginSuccessModalVisible = false

  constructor(private urlService : UrlService,private http: HttpClient, private firebaseAuth: FirebaseAuthService,
              private router : Router, private errorService: ErrorService,
              private successService: SuccessService, private localStorageService : LocalStorageService,
              private loginService: LoginService) {
    this.localStorageService.clear();
    this.handleFirebaseAuth.bind(this);
    console.log(urlService.getUrl("login"))
  }


  handleFirebaseTokenRetrieved(resp)
  {
    var user = this.firebaseAuth.getCurrentUser();
    var obs = this.loginService.login(user.email, resp, null);
    this.loggingIn = false;
    obs.subscribe((response)=> console.log(response));

  }

  handleFirebaseAuth(resp)
  {
    var user = this.firebaseAuth.getCurrentUser();
    localStorage.setItem('firebaseUser', JSON.stringify(user));
    this.success = true;
    this.loginSuccessModalVisible = true

  }


  loginWithFacebook()
  {
    this.firebaseAuth.doFacebookLogin();
  }

  loginWithGoogle()
  {
    this.firebaseAuth.doGoogleLogin();
  }

  handleError(reason)
  {
    if(reason === "Error: The password is invalid or the user does not have a password.")
    {
      this.error = 'Username or password incorrect';
    }
    else
    {
      this.error = 'Username or password incorrect';
    }
    console.log("Error: " + reason)
    this.loggingIn = false;
  }

  submit()
  {
    this.loggingIn = true;
    this.error = null;
    var promise = this.firebaseAuth.signInWithEmailAndPassword(this.username,this.password);
    promise.then((resp)=>this.handleFirebaseAuth(resp)).catch((resp)=>this.handleError(resp));
  }





  toggleShow()
  {
    this.show = !this.show;
  }



  ngOnInit() {
  }

  someFunction()
  {
    console.log("Some function")
  }
}
