import { NgModule } from '@angular/core';
import { Route, RouterModule } from '@angular/router';
import {PageNotFoundComponent} from "./page-not-found/page-not-found.component";
import {AuthGuardService} from "./auth-guard.service";
import {ComingSoonComponent} from "./coming-soon/coming-soon.component";
import {BeADriverComponent} from "./be-a-driver/be-a-driver.component";
import {PrivacyPolicyComponent} from "./privacy-policy/privacy-policy.component";
import {TermsOfServiceComponent} from "./terms-of-service/terms-of-service.component";
import {ServicesComponent} from "./hamburger/services/services.component";
import {AboutComponent} from "./hamburger/about/about.component";
import {FaqComponent} from "./hamburger/faq/faq.component";
import {HowItWorksComponent} from "./hamburger/how-it-works/how-it-works.component";
import {CitiesComponent} from "./hamburger/cities/cities.component";
import {OwnAnOilChangeComponent} from "./hamburger/own-an-oil-change/own-an-oil-change.component";
import {MainComponent} from "./main/main.component";
import {AnonymousPricingResultsComponent} from "./anonymous-pricing-results/anonymous-pricing-results.component";
import {InspectletEventsTestComponent} from "./inspectlet-events-test/inspectlet-events-test.component";
import {FirebaseComponent} from './firebase/firebase.component';
import {TwilioComponent} from './twilio/twilio.component';
import {GoogleAnalyticsComponent} from './google-analytics/google-analytics.component';
import {RedisComponent} from './redis/redis.component';
import {CmsComponent} from './cms/cms.component';
import {SendInBlueComponent} from './send-in-blue/send-in-blue.component';
import {DockerComponent} from './docker/docker.component';
import {InspectletComponent} from './inspectlet/inspectlet.component';
import {ConfigurationManagementComponent} from './configuration-management/configuration-management.component';
import {ZendeskComponent} from './zendesk/zendesk.component';
import {AngularComponent} from './angular/angular.component';
import {AwsS3Component} from './aws-s3/aws-s3.component';
import {AwsCloudfrontComponent} from './aws-cloudfront/aws-cloudfront.component';
import {ContinuousBuildDeployComponent} from './continuous-build-deploy/continuous-build-deploy.component';

const routes: Route[] = [


  {path: '',redirectTo:'/main/home', pathMatch: 'full'},

  {
    path: 'main',
    component:MainComponent,
    children: [
      {path: '', redirectTo: 'home', pathMatch: 'full'},
      {
        path: 'home',
        component: ComingSoonComponent
      },
      {
        path: 'firebase',
        component:FirebaseComponent,
      },
      {
        path: 'angular',
        component:AngularComponent,
      },
      {
        path: 'aws-s3',
        component:AwsS3Component,
      },
      {
        path: 'aws-cloudfront',
        component:AwsCloudfrontComponent,
      },
      {
        path: 'continuous-build-deploy',
        component:ContinuousBuildDeployComponent,
      },
      {
        path: 'twilio',
        component:TwilioComponent,
      },
      {
        path: 'google-analytics',
        component:GoogleAnalyticsComponent,
      },
      {
        path: 'redis',
        component:RedisComponent,
      },
      {
        path: 'cms',
        component:CmsComponent,
      },
      {
        path: 'send-in-blue',
        component:SendInBlueComponent,
      },
      {
        path: 'docker',
        component:DockerComponent,
      },
      {
        path: 'inspectlet',
        component:InspectletComponent,
      },
      {
        path: 'configuration-management',
        component:ConfigurationManagementComponent,
      },
      {
        path: 'zendesk',
        component:ZendeskComponent,
      },
      {
        path: 'be-a-driver',
        component:BeADriverComponent,
      },
      {
        path: 'privacy-policy',
        component:PrivacyPolicyComponent,
      },
      {
        path: 'terms-of-service',
        component:TermsOfServiceComponent,
      },
      {
        path: 'services',
        component:ServicesComponent,
      },
      {
        path: 'about',
        component:AboutComponent,
      },
      {
        path: 'faq',
        component:FaqComponent,
      },
      {
        path: 'how-it-works',
        component:HowItWorksComponent,
      },
      {
        path: 'cities',
        component:CitiesComponent,
      },
      {
        path: 'own-an-oil-change',
        component:OwnAnOilChangeComponent,
      },
      {
        path: 'events-test',
        component:InspectletEventsTestComponent,
      },
      {
        path: 'pricing-results',
        component:AnonymousPricingResultsComponent,
      },
    ]

  },
  {path: '**', redirectTo:'/main/home'}




];



@NgModule({
  imports: [RouterModule.forRoot(routes, {scrollPositionRestoration: 'enabled'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
