import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ServicesComponent } from './services/services.component';
import { AboutComponent } from './about/about.component';
import { HowItWorksComponent } from './how-it-works/how-it-works.component';
import { FaqComponent } from './faq/faq.component';
import {NavModule} from "../nav/nav.module";
import {FooterComponent} from "../footer/footer/footer.component";
import {FooterModule} from "../footer/footer.module";
import { CitiesComponent } from './cities/cities.component';
import { OwnAnOilChangeComponent } from './own-an-oil-change/own-an-oil-change.component';
import { WhyChooseFlubeitBannerComponent } from './why-choose-flubeit-banner/why-choose-flubeit-banner.component';

@NgModule({
  imports: [
    CommonModule,
    NavModule,
    FooterModule
  ],
  exports: [
    ServicesComponent,
    FaqComponent,
    HowItWorksComponent,
    AboutComponent,
    CitiesComponent,
    OwnAnOilChangeComponent
  ],
  declarations: [ServicesComponent, AboutComponent, HowItWorksComponent, FaqComponent, CitiesComponent, OwnAnOilChangeComponent, WhyChooseFlubeitBannerComponent]
})
export class HamburgerModule { }
