import { Injectable } from '@angular/core';
import {ActivatedRoute} from "@angular/router";

@Injectable({
  providedIn: 'root'
})
export class VersionUtilService {

  version: string;
  campaign: string;

  constructor(  private route: ActivatedRoute) {
    this.version = this.route.snapshot.queryParamMap.get('version');
    this.campaign = this.route.snapshot.queryParamMap.get('campaign');

    if(this.version === null || this.version === undefined)
    {
      this.version = 'X';
    }
    if(this.campaign === null || this.campaign === undefined)
    {
      this.campaign = 'none';
    }
  }


  versionEquals(versions)
  {
    var isEqual = false;
    versions.forEach(version => {
      if(version === this.version && !isEqual)
      {
        isEqual = true;
      }
    });

    return isEqual;

  }
}
