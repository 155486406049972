import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-generic-modal',
  templateUrl: './generic-modal.component.html',
  styleUrls: ['./generic-modal.component.css']
})
export class GenericModalComponent implements OnInit {
  @Input() title: string;
  @Input() body: string;
  @Input() buttonText: string;
  @Input() visible: boolean;
  @Output() visibleChange : EventEmitter<any> = new EventEmitter<any>();
  @Output() onOk : EventEmitter<any> = new EventEmitter<any>();
  @Output() onCancel : EventEmitter<any> = new EventEmitter<any>();

  constructor() { }

  ngOnInit() {
  }


  close()
  {
    this.visibleChange.emit(false);
  }

  okClicked()
  {
    this.onOk.emit(false);
  }

  onCancelClicked()
  {
    this.onCancel.emit(false);
  }

}
