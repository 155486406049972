import {Component, ElementRef, OnInit} from '@angular/core';
import {VersionUtilService} from "../version-util.service";
import {UrlService} from "../url.service";
import {IpAddressService} from "../ip-address.service";
import {IpAddress} from "../be-a-driver/ip.model";
import {HttpClient} from "@angular/common/http";
import {DeviceUtilService} from "../device-util.service";
import {CookieService} from "ngx-cookie-service";

@Component({
  selector: 'app-lead-enhancer',
  templateUrl: './lead-enhancer.component.html',
  styleUrls: ['./lead-enhancer.component.css']
})
export class LeadEnhancerComponent implements OnInit {

  campaign: string;
  leadUrl: string;
  lead: any = {};
  submitted: boolean = false;
  campaigns:any = {};
  campaignData : any;
  cookiesEnabled: boolean = false;


  constructor(private urlService: UrlService, private versionUtil: VersionUtilService,
  private ipService: IpAddressService,private http: HttpClient, private deviceUtil: DeviceUtilService,private elementRef:ElementRef, private cookieService: CookieService) {
    this.leadUrl = urlService.getUrl("lead");
    this.campaign = versionUtil.campaign;
    this.ipService.getIpAddress().subscribe((data: IpAddress) => {
      this.lead.ipAddress = data.ip;
      console.log(data);
    });
    this.lead = deviceUtil.initLead();
    cookieService.set("test","test");
    this.cookiesEnabled = cookieService.check("test");




    var freeOilChange = {"title":"Coming soon to Austin!", "content":["Enter now for a chance to win a free oil change!"],
      "submitTitle":"Thank you","submitContent":"Thank you for your interest. We will be in touch."}
    var beOneOfTheFirst = {"title":"Be one of the first in Austin", "content":["Space is limited while we are in early access.",
    "Sign up now to be included."], "submitTitle":"Thank you","submitContent":"Thank you for your interest. We will be in touch."}
    this.campaigns["freeOilChange"] = freeOilChange;
    this.campaigns["beOneOfTheFirst"] = beOneOfTheFirst;
    this.campaignData = this.campaigns[this.campaign];
    console.log(this.campaignData)


  }

  ngOnInit() {
  }

  ngAfterViewInit() {
    var s = document.createElement("script");
    s.type = "text/javascript";
    s.src = "https://js.gleam.io/e.js";
    this.elementRef.nativeElement.appendChild(s);

    var raffle = document.createElement("script");
    raffle.type = "text/javascript";
    raffle.src = "https://widget-prime.rafflecopter.com/launch.js";
    this.elementRef.nativeElement.appendChild(raffle);

  }

  postLead()
  {
    console.log("Posting lead")
    this.lead.leadType = 'CUSTOMER';
    this.lead.version = this.versionUtil.version;
    this.lead.campaign = this.campaign;
    var obs = this.http.post<any>(this.leadUrl,  this.lead)
    obs.subscribe((response)=> {
      if(response.status === 'OK')
      {
        this.submitted = true;

      }



    })

  }

}
