import { Component, OnInit } from '@angular/core';
import {FirebaseAuthService} from "../firebase-auth.service";
import {LoginService} from "../login.service";
import {ErrorService} from "../error.service";
import {WindowService} from "../window.service";
import * as firebase from "firebase";
import {PhoneNumber} from "./phonenumber-model";
import {Router} from "@angular/router";
import {FormBuilder, FormControl, FormGroup, FormGroupDirective, NgForm, Validators} from "@angular/forms";
import {ErrorStateMatcher} from "@angular/material";




export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const invalidCtrl = !!(control && control.invalid && control.parent.dirty);
    const invalidParent = !!(control && control.parent && control.parent.invalid && control.parent.dirty);

    return (invalidCtrl || invalidParent);
  }
}



@Component({
  selector: 'app-create-account',
  templateUrl: './create-account.component.html',
  styleUrls: ['./create-account.component.css']
})




export class CreateAccountComponent implements OnInit {

  account:any = {};
  show: boolean;
  confirmShow:boolean;
  error: undefined;
  successMessage; undefined;
  sendingTextVerification:boolean = false;
  confirmingResult:boolean = false;
  codeVerified:boolean = false;
  submitting:boolean = false;
  form: FormGroup;
  confirmPass:string;
  matcher = new MyErrorStateMatcher();
  success:boolean;
  windowRef: any;
  phoneNumber = new PhoneNumber()
  verificationCode: string;
  user: any;
  accountCreatedModalVisible:boolean = false;


  validation_messages = {
    'firstName': [
      { type: 'required', message: 'First name is required' },
    ],
    'lastName': [
      { type: 'required', message: 'Last name is required' },

    ],
    'email': [
      { type: 'required', message: 'Email is required' },
      { type: 'email', message: 'Enter a valid email' }

    ],
    'password': [
      { type: 'required', message: 'Password is required' },

    ]
  }

  constructor(private firebaseAuthService: FirebaseAuthService,
              private loginService: LoginService, private errorService: ErrorService,
              private win: WindowService,
              private router: Router,private fb: FormBuilder) {

    this.form = this.fb.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      email: new FormControl('', Validators.compose([
        Validators.required,
        Validators.email,
      ])),
      password: ['', Validators.required],
      confirmPassword: ['', Validators.required]


    } ,{validator: this.checkPasswords })



    this.show  = false;
    this.account.email = localStorage.getItem("getStartedEmail");
    this.account.zipCode = localStorage.getItem("getStartedZip");
    this.loginService.accountCreated.subscribe(data=>
    {
      this.accountCreatedModalVisible = true;
    });




  }

  ngOnInit() {

    this.accountCreatedModalVisible = false;
    this.account.email = localStorage.getItem("getStartedEmail");
    this.account.zipCode = localStorage.getItem("getStartedZip");
    this.windowRef = this.win.windowRef
    this.windowRef.recaptchaVerifier = new firebase.auth.RecaptchaVerifier('recaptcha-container')

    this.windowRef.recaptchaVerifier.render()



  }

  createAccount(event)
  {
    if(this.form.invalid)
    {
      return;
    }
    console.log("Creating account")
    this.tryRegister();
  }

  password() {
    this.show = !this.show;
  }

  confirmPassword()
  {
    this.confirmShow = !this.confirmShow;
  }



  handleError(reason)
  {
    if(reason === "Error: The password is invalid or the user does not have a password.")
    {
      this.errorService.addErrors([{error:"Error Occurred",message:'Username or password incorrect'}])
    }
    else
    {
      this.errorService.addErrors([{error:"Error Occurred",message:reason}])
    }
    console.log("Error: " + reason)
  }

  tryRegister(){
    this.submitting = true;
    this.error = undefined;
    this.firebaseAuthService.doRegister(this.account.email, this.account.password)
      .then(res => {
        this.error = undefined;
        this.successMessage = "Your account has been created";
        var user = this.firebaseAuthService.getCurrentUser();
        localStorage.setItem('firebaseUser', JSON.stringify(user));
        this.accountCreatedModalVisible = true
        this.submitting = false;
        this.success = true;

      }, err => {
        console.log(err);
        this.error = err.message;
        if (err.code === 'auth/email-already-in-use') {
          this.form.controls.email.enable();
        }
        this.successMessage = "";
        this.submitting = false;

      })
  }


  sendLoginCode() {

    this.sendingTextVerification = true;
    const appVerifier = this.windowRef.recaptchaVerifier;

    const num = this.phoneNumber.e164;

    this.account.phone = this.phoneNumber.dialPhone;

    firebase.auth().signInWithPhoneNumber(num, appVerifier)
      .then(result => {

        this.windowRef.confirmationResult = result;
        this.account.email = localStorage.getItem("getStartedEmail");
        this.account.zipCode = localStorage.getItem("getStartedZip");
        this.sendingTextVerification = false;


      })
      .catch( error => console.log(error) );

  }

  verifyLoginCode() {
    this.confirmingResult = true;
    this.windowRef.confirmationResult
      .confirm(this.verificationCode)
      .then( result => {

        this.user = result.user;
        this.confirmingResult = false;
        this.codeVerified = true;

      })
      .catch( error => this.confirmingResult = false);
  }




  checkPasswords(group: FormGroup) { // here we have the 'passwords' group
    let pass = group.controls.password.value;
    let confirmPass = group.controls.confirmPassword.value;

    return pass === confirmPass ? null : { notSame: true }
  }

  scroll(event) {

    const target = event.target || event.srcElement;
    target.scrollIntoView({ behavior: 'smooth', block: 'start' });

  }


}
