import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-zendesk',
  templateUrl: './zendesk.component.html',
  styleUrls: ['./zendesk.component.css']
})
export class ZendeskComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
