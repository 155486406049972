import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-send-in-blue',
  templateUrl: './send-in-blue.component.html',
  styleUrls: ['./send-in-blue.component.css']
})
export class SendInBlueComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
