import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-configuration-management',
  templateUrl: './configuration-management.component.html',
  styleUrls: ['./configuration-management.component.css']
})
export class ConfigurationManagementComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
