import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-hamburger-menu',
  templateUrl: './hamburger-menu.component.html',
  styleUrls: ['./hamburger-menu.component.css']
})
export class HamburgerMenuComponent implements OnInit {

  @Input() showHamburgerMenu: boolean = false;
  @Output() hamburgerChange: EventEmitter<any> = new EventEmitter<any>();

  constructor() { }

  ngOnInit() {
  }

  toggleHamburgerMenu()
  {
    this.showHamburgerMenu = false;
    this.hamburgerChange.emit(this.showHamburgerMenu)
  }

}
