import { Component, OnInit } from '@angular/core';
import {AnonymousPricingService} from "../anonymous-pricing.service";
import {SanityService} from "../sanity.service";
import {trigger, state, style, animate, transition, stagger, query, keyframes} from '@angular/animations';
import {environment} from "../../environments/environment";
import {isNullOrUndefined} from "util";
import {ConfigService} from "../config.service";

@Component({
  selector: 'app-anonymous-pricing-results',
  templateUrl: './anonymous-pricing-results.component.html',
  styleUrls: ['./anonymous-pricing-results.component.css'],
  animations: [
    trigger('fadeInOut', [
      state('void', style({
        opacity: 0
      })),
      transition('void <=> *', animate(1000)),
    ]),
    trigger('listAnimation', [
      transition('* => *', [

        query(':enter', style({ opacity: 0 }), {optional: true}),

        query(':enter', stagger('300ms', [
          animate('1s ease-in', keyframes([
            style({opacity: 0, transform: 'translateY(-75%)', offset: 0}),
            style({opacity: .5, transform: 'translateY(35px)',  offset: 0.3}),
            style({opacity: 1, transform: 'translateY(0)',     offset: 1.0}),
          ]))]), {optional: true}),
        query(':leave', stagger('300ms', [
          animate('1s ease-in', keyframes([
            style({opacity: 1, transform: 'translateY(0)', offset: 0}),
            style({opacity: .5, transform: 'translateY(35px)',  offset: 0.3}),
            style({opacity: 0, transform: 'translateY(-75%)',     offset: 1.0}),
          ]))]), {optional: true})
      ])
    ])
  ]
})
export class AnonymousPricingResultsComponent implements OnInit {

  stores:any = {};
  zipCode:string;
  items:any = [];
  totalItems:any = [];
  itemTypes:Set<string> = new Set();
  currentlyShowing:any = "All"
  shopUrl;
  getStartedUrl;
  loginUrl;
  vehicle:any = {};
  firstTimeDiscountEnabled:string = "0";
  configSubscription:any;


  constructor(private anonymousPricingService: AnonymousPricingService,
              private sanityService: SanityService, private configService: ConfigService) {
    this.shopUrl  = environment.shopUrl;
    this.getStartedUrl = this.shopUrl + "/main/get-started";
    this.loginUrl = this.shopUrl + "/main/login";


    this.firstTimeDiscountEnabled = this.configService.getValueWithRefresh('FIRST_TIME_DISCOUNT_ENABLED');

    this.configSubscription = this.configService.configUpdated.subscribe(
      (data) => {
        this.firstTimeDiscountEnabled = data['FIRST_TIME_DISCOUNT_ENABLED'];

      }
    )

    this.anonymousPricingService.onStoresUpdated.subscribe(
      (data) => {
        this.init();
      }
    )

  }

  init()
  {
    this.vehicle = this.anonymousPricingService.vehicle;
    this.zipCode = this.anonymousPricingService.zipCode;
    this.stores = this.anonymousPricingService.stores;
    //get all items for each store
    this.totalItems = [];
    for(var i = 0; i < this.stores.length; i++)
    {
      this.populateItems(this.stores[i])
    }


  }
  ngOnInit() {
    this.init();

  }

  populateItems(store)
  {
    var itemsObs = this.sanityService.getProducts(store._id)
    itemsObs.subscribe(
      (items:any) =>
      {
        this.items = items.result[0].products;
        for(var i = 0; i < this.items.length; i++)
        {
          var storeItem = {
            "store":store,
            "item":this.items[i],
            "hidden":false,
          }

          if(this.firstTimeDiscountEnabled === '0')
          {
            if(!isNullOrUndefined(this.items[i].variant) && !isNullOrUndefined(this.items[i].variant.productVariantType) && this.items[i].variant.productVariantType.type === 'FIRST_TIME_DISCOUNT')
            {
              continue;
            }
          }

          this.setItemPrice(storeItem);
          //WEBAPP 78 : We only want to show discounted items
          if(this.items[i].discount != 0)
          {
            this.totalItems.push(storeItem)
            this.itemTypes.add(this.items[i].title)

          }

        }
        console.log("Updated total items")

      }
    )

  }

  setItemPrice(storeItem)
  {
    console.log("Setting item price");
    var item = storeItem.item;
    if(isNullOrUndefined(item.discount) || item.discount === 0)
    {
      return;
    }
    item.actualPrice = ((item.price) / (1 - (item.discount/100))).toFixed(2);

  }

  //1 = all, 2 = conventional, 3 = synthetic
  filterResults(value) {
    console.log("Filtering results")
    this.currentlyShowing = value;
    for (var i = 0; i < this.totalItems.length; i++) {
      if(value !== this.totalItems[i].item.title)
      {
        this.totalItems[i].hidden = true;
      }
      else
      {
        this.totalItems[i].hidden = false;
      }
      if(value === 'All')
      {
        this.totalItems[i].hidden = false;
      }

    }
  }

  clearItems()
  {
    this.totalItems = [];
  }


}
