import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NavComponent } from './nav/nav.component';
import {RouterModule} from "@angular/router";
import {MatIconModule, MatMenuModule, MatDividerModule} from "@angular/material";

@NgModule({
  imports: [

    CommonModule,
    RouterModule,
    MatMenuModule,
    MatIconModule,
    MatDividerModule,

  ],
  exports: [
    NavComponent,
  ],
  declarations: [NavComponent]
})
export class NavModule { }
