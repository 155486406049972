import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoadingSpinnerComponent } from './loading-spinner/loading-spinner.component';

@NgModule({
  imports: [
    CommonModule
  ],
  exports: [
    LoadingSpinnerComponent
  ],
  declarations: [LoadingSpinnerComponent]
})
export class LoadingModule { }
