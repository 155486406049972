import { Component, OnInit } from '@angular/core';
import {InspectletService} from '../inspectlet.service';

@Component({
  selector: 'app-inspectlet',
  templateUrl: './inspectlet.component.html',
  styleUrls: ['./inspectlet.component.css']
})
export class InspectletComponent implements OnInit {

  constructor(private inspectletService: InspectletService) { }

  data:any = {};

  ngOnInit() {
  }


  setTag()
  {
    this.inspectletService.addTag(this.data.tagValue);
    console.log(this.data.tagValue)
  }

}
