import { Component, OnInit } from '@angular/core';
import {IpAddressService} from "../ip-address.service";
import {InspectletService} from "../inspectlet.service";

@Component({
  selector: 'app-inspectlet-events-test',
  templateUrl: './inspectlet-events-test.component.html',
  styleUrls: ['./inspectlet-events-test.component.css']
})
export class InspectletEventsTestComponent implements OnInit {

  constructor(private inspectletService: InspectletService) { }

  data:any = {};

  ngOnInit() {
  }


  setTag()
  {
    this.inspectletService.addTag(this.data.tagValue);
    console.log(this.data.tagValue)
  }

}
