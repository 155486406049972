import { Injectable } from '@angular/core';
import {Subject} from "rxjs/index";

@Injectable({
  providedIn: 'root'
})
export class SuccessService {

  private success = new Subject<any>();

  constructor() { }

  public addSuccess = (success: any): void =>
    this.success.next(success);

  public getSuccess = () =>
    this.success.asObservable();

}
