import { Injectable } from '@angular/core';
import {environment} from "../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class UrlService {

  baseUrl: string = environment.flubeItBaseUrl;
  stripeBase: string = this.baseUrl + "stripe";

  urls: any  = {
    login: this.baseUrl + "oauth/token",
    stripeAccount: this.stripeBase + "/account",
    stripeBank: this.stripeBase + "/bank",
    profile: this.baseUrl + "profile",
    lead: this.baseUrl + "lead"


  }

  getUrl(url)
  {
    return this.urls[url];
  }

  constructor() { }


}
