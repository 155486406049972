import {Component, OnDestroy, ViewChild} from '@angular/core';
import {TestService} from "./test.service";
import {HttpClient} from "@angular/common/http";
import {ErrorService} from "./error.service";
import {Subject} from "rxjs/index";
import {takeUntil} from "rxjs/internal/operators";
import {MatDialog} from "@angular/material";
import {BasicDialogComponent} from "./basic-dialog/basic-dialog.component";
import {SuccessService} from "./success.service";
import {NavigationEnd, Router} from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnDestroy {
  title = 'skills-ui';
  inputText: string = "Testing"
  error: string;

  private ngUnsubscribe = new Subject();


  constructor(private svc : TestService, private http: HttpClient,private errorService: ErrorService,
              public dialog: MatDialog, private successService: SuccessService,private router: Router) {

    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      console.log("scrolling")
      window.scrollTo(0, 0)
    });

    this.initializeErrors();
    this.initializeSuccesses();
  }

  onActivate(event) {
    window.scroll(0,0);
  }

  ngOnInit() {

  }

  ngOnDestroy()
  {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }


  private initializeErrors()
  {
    this
      .errorService
      .getErrors()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((errors) =>
      {
        //this.displayErrorRef.error = errors
        //this.displayErrorRef.show();
        console.log(errors);
        let d = {
          data: {
            title: errors[0].error,
            message: errors[0].message
          }
        }

        this.dialog.open(BasicDialogComponent,d );

      });
  }

  private initializeSuccesses()
  {
    this
      .successService
      .getSuccess()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((success) =>
      {
        //this.displayErrorRef.error = errors
        //this.displayErrorRef.show();
        console.log(success);
        let d = {
          data: {
            title: success.title,
            message: success.message
          }
        }

        this.dialog.open(BasicDialogComponent,d );

      });
  }

}
