import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-aws-s3',
  templateUrl: './aws-s3.component.html',
  styleUrls: ['./aws-s3.component.css']
})
export class AwsS3Component implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
