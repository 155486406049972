import {Component, ElementRef, OnInit} from '@angular/core';
import {MessageService} from '../message.service';

@Component({
  selector: 'app-twilio',
  templateUrl: './twilio.component.html',
  styleUrls: ['./twilio.component.css']
})
export class TwilioComponent implements OnInit {

  messages: {}

  constructor(private elementRef:ElementRef,private messageService: MessageService) { }

  ngOnInit() {
    this.loadMessages()
  }

  ngAfterViewInit() {
    var raffle = document.createElement("script");
    raffle.type = "text/javascript";
    raffle.src = "../../assets/js/twilioFlexScript.js"
    this.elementRef.nativeElement.appendChild(raffle);

  }

  loadMessages()
  {
    var obs = this.messageService.getMessages();
    obs.subscribe(
      (data:any) =>
      {
        console.log("Got messages");
        console.log(data);
        this.messages = data

      })
  }

}
