import { Component, OnInit } from '@angular/core';
import {environment} from "../../../environments/environment";
import {VersionUtilService} from "../../version-util.service";

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

  public version: string = environment.VERSION;
  using:boolean = false;
  resources:boolean  = false;
  community: boolean = false;
  policies: boolean = false;
  constructor(private versionUtil: VersionUtilService) {

  }

  ngOnInit() {
  }

  versionEquals(versions)
  {
    return this.versionUtil.versionEquals(versions);
  }

  showUsing()
  {
    console.log("Updating using")
    this.using = !this.using;
  }

}
