import { Injectable } from '@angular/core';
import {isNullOrUndefined} from "util";

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor() {

  }


  getBearerToken()
  {
    let token = JSON.parse(localStorage.getItem('token'));
    if(token)
    {
      return token.access_token;
    }
    else
    {
      return "";
    }

  }


  getLoggedInUser()
  {
    let token = JSON.parse(localStorage.getItem('token'));
    if(token)
    {
      return token.username;
    }
    else
    {
      return "";
    }

  }

  getLoggedInUserId()
  {
    let user = JSON.parse(localStorage.getItem('firebaseUser'));
    if(user)
    {
      return user.uid;
    }
    else
    {
      return "";
    }
  }

  isAuthenticated()
  {
    let token = JSON.parse(localStorage.getItem('token'));
    if(isNullOrUndefined(token))
    {
      return false;
    }
    else
    {
      var date = new Date();
      var expirationDate = new Date(date.getTime() + (1000 * token.expires_in))
      if(expirationDate < date)
      {
        return false;
      }
      else
      {
        return true;
      }

    }


  }
}
