import {EventEmitter, Injectable, Output} from '@angular/core';
import {zip} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class AnonymousPricingService {


  @Output() onStoresUpdated: EventEmitter<any> = new EventEmitter<any>();


  stores:any = {};
  zipCode:string;
  vehicle:any = {};

  constructor() { }

  setStores(stores)
  {
    this.stores = stores;
    this.onStoresUpdated.emit(this.stores);
  }

  setZipCode(zipCode)
  {
    this.zipCode = zipCode;
  }

  setVehicle(vehicle)
  {
    this.vehicle = vehicle;
  }
}
