import {
  Component,
  OnInit,
  ElementRef,
  Renderer2,
  ViewChild,
  Output,
  EventEmitter,
  SimpleChanges,
  Input
} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';
import {MatIconRegistry} from '@angular/material';
import {DragScrollComponent} from "ngx-drag-scroll/lib";
import {isNullOrUndefined} from "util";

@Component({
  selector: 'app-generic-carousel',
  templateUrl: './generic-carousel.component.html',
  styleUrls: ['./generic-carousel.component.css']
})
export class GenericCarouselComponent implements OnInit {
  title = 'app works!';
  hideScrollbar;
  disabled;
  xDisabled;
  yDisabled;
  @Input() items:any;
  @Input() cartButtons: false;
  @Input() height:any = "300px"
  imagelist = [
    'luke.png',
    'chubaka.png',
    'boba.png',
    'c3po.png',
    'leia.png',
    'obi.png',
    'r2d2.png',
    'storm.png',
    'varder.png',
    'yoda.png',
    'yolo.png'
  ];
  leftNavDisabled = false;
  rightNavDisabled = false;
  index = 0;
  @Output() onDeleteItemUpdated: EventEmitter<any> = new EventEmitter<any>();


  @ViewChild('nav', {read: DragScrollComponent}) ds: DragScrollComponent;

  @Output() onItemClicked: EventEmitter<any> = new EventEmitter<any>();
  @Output() onItemAdded: EventEmitter<any> = new EventEmitter<any>();


  constructor(
    sanitizer: DomSanitizer,
    private element: ElementRef,
    private renderer: Renderer2
  ) {

  }


  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    console.log("simple changes")
    if(!isNullOrUndefined(changes.items))
    {
      this.items = changes.items.currentValue;
    }


  }

  clickItem(item) {
    console.log('item clicked');
    this.onItemClicked.emit(item);
  }

  remove() {
    this.imagelist.pop();
  }

  toggleHideSB() {
    this.hideScrollbar = !this.hideScrollbar;
  }

  toggleDisable() {
    this.disabled = !this.disabled;
  }

  toggleXDisable() {
    this.xDisabled = !this.xDisabled;
  }

  toggleYDisable() {
    this.yDisabled = !this.yDisabled;
  }

  moveLeft() {
    this.ds.moveLeft();
  }

  moveRight() {
    this.ds.moveRight();
  }

  leftBoundStat(reachesLeftBound: boolean) {
    this.leftNavDisabled = reachesLeftBound;
  }

  rightBoundStat(reachesRightBound: boolean) {
    this.rightNavDisabled = reachesRightBound;
  }

  onSnapAnimationFinished() {
    console.log('snap animation finished');
  }

  onIndexChanged(idx) {
    this.index = idx;
    console.log('current index: ' + idx);
  }

  onDragScrollInitialized() {
    console.log('first demo drag scroll has been initialized.');
  }

  deleteItem(item)
  {
    this.onDeleteItemUpdated.emit(item);
  }

  addItem(item)
  {
    this.onItemAdded.emit(item);
  }

}
