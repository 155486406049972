import { Injectable } from '@angular/core';
import { AngularFireAuth } from 'angularfire2/auth';
import * as firebase from 'firebase/app';


@Injectable({
  providedIn: 'root'
})
export class FirebaseAuthService {

  constructor(public afAuth: AngularFireAuth) { }

  doFacebookLogin(){
    return new Promise<any>((resolve, reject) => {
      let provider = new firebase.auth.FacebookAuthProvider();
      this.afAuth.auth
        .signInWithPopup(provider)
        .then(res => {
          console.log("Got response from facebook login")
          resolve(res);
        }, err => {
          console.log(err);
          reject(err);
        })
    })
  }

  doGoogleLogin(){
    return new Promise<any>((resolve, reject) => {
      let provider = new firebase.auth.GoogleAuthProvider();
      provider.addScope('profile');
      provider.addScope('email');
      this.afAuth.auth
        .signInWithPopup(provider)
        .then(res => {
          console.log("Got response from google login")
          resolve(res);
        })
    })
  }

  signInWithEmailAndPassword(username: string,password : string){
    return new Promise<any>((resolve, reject) => {
      firebase.auth().signInWithEmailAndPassword(username, password)
        .then(res => {
          resolve(res);
        }, err => reject(err))
    })
  }

  doRegister(email, password){
    return new Promise<any>((resolve, reject) => {
      firebase.auth().createUserWithEmailAndPassword(email, password)
        .then(res => {
          resolve(res);
        }, err => reject(err))
    })
  }

  getCurrentUser()
  {
    return firebase.auth().currentUser;
  }

  sendPasswordReset(email)
  {
    var auth = firebase.auth();
    var emailAddress = email;

    return auth.sendPasswordResetEmail(emailAddress);
  }


}
