import {EventEmitter, Injectable, Output} from '@angular/core';
import {SanityService} from "./sanity.service";
import {isNullOrUndefined} from "util";

@Injectable({
  providedIn: 'root'
})
export class ConfigService {

  config:any = {};
  configObs:any;
  @Output() configUpdated: EventEmitter<any> = new EventEmitter<any>();

  constructor(private sanityService: SanityService)
  {
    this.config = this.sanityService.loadConfig();
  }

  refreshConfig()
  {
    console.log("Refreshing config")
    this.configObs = this.sanityService.loadConfig();
    this.configObs.subscribe(
      (data:any)=>
      {
        console.log("Got config")
        if(!isNullOrUndefined(data) && !isNullOrUndefined(data.result))
        {
          for(var i = 0; i < data.result.length; i++)
          {
            this.config[data.result[i].key] = data.result[i].value;
          }

          this.configUpdated.emit(this.config);
        }



      });

  }

  getValueWithRefresh(key)
  {
    this.refreshConfig();
    return this.config[key];
  }


}
