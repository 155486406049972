import {EventEmitter, Injectable} from '@angular/core';
import {map} from "rxjs/operators";
import {HttpClient} from "@angular/common/http";
import {UrlService} from "./url.service";
import {isNullOrUndefined} from "util";
import {Router} from "@angular/router";
import {AuthService} from "./auth.service";

@Injectable({
  providedIn: 'root'
})
export class LoginService {



  accountCreated: EventEmitter<any> = new EventEmitter<any>();


  constructor(private http: HttpClient, private urlService: UrlService,
              private router: Router, private authService: AuthService) { }


  login(username, password,account) {
    var grant_type = 'password';
    console.log("Logging in")
    let headers = {'Authorization': 'Basic Zmx1YmUtaXQtd2ViLWFwcDpzZWNyZXQ=', 'Content-Type': 'application/x-www-form-urlencoded'}
    var payload = 'grant_type=password&username=' + username.toLowerCase() + '&password=' + password;

    return this.http.post<any>(this.urlService.getUrl("login"),  payload,{headers:headers})
      .pipe(map(resp => {
        // login successful if there's a user in the response
        if (resp) {
          // store user details and basic auth credentials in local storage
          // to keep user logged in between page refreshes
          var token = JSON.stringify(resp);
          localStorage.setItem('token', token );
          if (!isNullOrUndefined(account)) {
            // We need to create the profile first
            console.log('Creating account')

            // we need to listen for when profile is created
            const loggedInUser = this.authService.getLoggedInUser();


          }
          else {




          }
        }

        return token;
      }));
  }
}
