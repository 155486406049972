import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.css']
})
export class MainComponent implements OnInit {

  showHamburgerMenu: boolean = false;
  constructor() { }

  ngOnInit() {
  }

  hamburgerChanged(value)
  {
    this.showHamburgerMenu = value;
  }
}
