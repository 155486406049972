import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-why-choose-flubeit-banner',
  templateUrl: './why-choose-flubeit-banner.component.html',
  styleUrls: ['./why-choose-flubeit-banner.component.css']
})
export class WhyChooseFlubeitBannerComponent implements OnInit {

  @Input() title = 'Cory Kelly Services Include';

  constructor() { }

  ngOnInit() {
  }

}
