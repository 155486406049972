import {EventEmitter, Injectable} from '@angular/core';

declare let __insp: any;


@Injectable({
  providedIn: 'root'
})


export class InspectletService {

  tagComplete:EventEmitter<any> = new EventEmitter();

  constructor() { }

  addTag(tag)
  {
    console.log("Adding tag: " + tag)
    __insp.push(['tagSession', tag]);
    this.tagComplete.emit(tag);

  }
}
