import { Component, OnInit } from '@angular/core';
import {IpAddressService} from "../ip-address.service";
import {UrlService} from "../url.service";
import {HttpClient} from "@angular/common/http";
import {SuccessService} from "../success.service";
import {DeviceUtilService} from "../device-util.service";
import {VersionUtilService} from "../version-util.service";
import {IpAddress} from "../be-a-driver/ip.model";

@Component({
  selector: 'app-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.css']
})
export class PrivacyPolicyComponent implements OnInit {


  showMobileMenu: boolean = false;
  isReadOnlyWithEmail: boolean = true;
  isReadOnly: boolean = true;
  showStayTuned: boolean = false;
  lead: any = {};
  leadUrl: string;
  submitted: boolean = false;
  deviceInfo = null;
  version: string = 'X';
  campaign: string;

  constructor(private ipService: IpAddressService, private urlService: UrlService,
              private http: HttpClient, private successService: SuccessService, private deviceUtil: DeviceUtilService,
              private versionUtil: VersionUtilService) {
    this.version = versionUtil.version;
    this.campaign = versionUtil.campaign;
    this.leadUrl = urlService.getUrl("lead");
    this.ipService.getIpAddress().subscribe((data: IpAddress) => {
      this.lead.ipAddress = data.ip;
      console.log(data);
    });

    this.lead = deviceUtil.initLead();

  }




  ngOnInit() {
  }

  postLead()
  {
    console.log("Posting lead")
    this.lead.leadType = 'CUSTOMER';
    this.lead.campaign = this.campaign;
    var obs = this.http.post<any>(this.leadUrl,  this.lead)
    obs.subscribe((response)=> {
      if(response.status === 'OK')
      {
        this.submitted = true;

      }



    })

  }


  toggleIsReadOnlyWithEmail()
  {

    this.isReadOnlyWithEmail = false;
  }

  toggleIsReadOnly()
  {

    this.isReadOnly = false;
  }

  toggleMobileMenu()
  {
    this.showMobileMenu = !this.showMobileMenu;
  }

  toggleStayTuned()
  {
    this.showStayTuned = !this.showStayTuned;
  }


  requiresReadOnly()
  {
    return this.deviceUtil.requiresReadOnly();
  }

}
