import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {isNullOrUndefined} from "util";
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {SanityService} from "../sanity.service";
import {environment} from "../../environments/environment";
import {Router} from "@angular/router";
import {AnonymousPricingService} from "../anonymous-pricing.service";
import {InspectletService} from "../inspectlet.service";

@Component({
  selector: 'app-anonymous-pricing',
  templateUrl: './anonymous-pricing.component.html',
  styleUrls: ['./anonymous-pricing.component.css']
})
export class AnonymousPricingComponent implements OnInit {
  message:string = undefined;
  getStartedData:any = {};
  visible = false;
  form: FormGroup;
  vehicle:any = {};
  data:any = {};
  storeObs:any;
  stores:any;
  filteredStores:any;
  storeId:any;
  selectedStore:any;
  items:any;
  step:number = 1;
  shopUrl: string;
  error:any;
  vehicleMake:any;
  vehicleYear:any;
  vehicleModel:any;
  notAvailable:boolean = false;
  @ViewChild("vehicleModel") myId: ElementRef;
  submitting = false;
  submitted = false;

  position = new FormControl("right");

  validation_messages = {
    'zipCode': [
      { type: 'required', message: 'Zip code is required. ' },
      { type: 'pattern', message: 'Enter a valid zip code. ' }

    ],
    'vehicleYear': [
      { type: 'required', message: 'Vehicle year is required. ' }
    ],
    'vehicleMake': [
      { type: 'required', message: 'Vehicle make is required. ' }
    ],
    'vehicleModel': [
      { type: 'required', message: 'Vehicle model is required. ' }
    ]
  }

  constructor(private sanityService: SanityService,private fb: FormBuilder,
              private elementRef:ElementRef, private router : Router, private anonymousPricingService: AnonymousPricingService,
              private inspectletService: InspectletService) {

    this.shopUrl  = environment.shopUrl;

    this.form = this.fb.group({
      vehicleYear: new FormControl('', Validators.compose([
        Validators.required,
      ])),
      vehicleMake: new FormControl('', Validators.compose([
        Validators.required,
      ])),
      vehicleModel: new FormControl('', Validators.compose([
        Validators.required,
      ])),
      zipCode: new FormControl('', Validators.compose([
        Validators.required,
        Validators.pattern('^[0-9]{5}(?:-[0-9]{4})?$')
      ])),
    })

    this.form.get('vehicleMake').disable();
    this.form.get('vehicleModel').disable();

  }

  ngOnInit() {
    console.log("loading")

    var s = document.createElement("script");
    s.type = "text/javascript";
    s.src = "../../assets/js/carquery.js";
    this.elementRef.nativeElement.appendChild(s);

  }


  submit()
  {
  }


  yearChanged(year)
  {
    this.form.get('vehicleMake').enable();
    this.tagVehicleYear();

  }

  modelChanged(model)
  {
    this.tagVehicleModel()
  }

  makeChanged(model)
  {
    this.tagVehicleMake();

    //Doing this so we can set the model to the first value that is loaded
    setTimeout(()=>{    //<<<---    using ()=> syntax
      console.log(this.myId.nativeElement.options[0].value)
      this.vehicle.model = this.myId.nativeElement.options[0].value;
      this.form.get('vehicleModel').enable();
      this.form.controls['vehicleModel'].setValue(this.vehicle.model);


    }, 500);

  }

  getStores()
  {
    this.inspectletService.addTag("user clicked SEARCH")
    this.submitted = true;
    if (this.form.invalid) {
      return;
    }
    this.submitting = true;
    this.notAvailable = false;
    this.anonymousPricingService.setZipCode(this.data.zipCode);
    this.anonymousPricingService.setVehicle(this.vehicle);
    this.items = [];
    //TODO
    //this.storeObs = this.sanityService.getServiceLocationsByZip(this.data.zipCode)
    this.storeObs = this.sanityService.getServiceLocationsByZip("54479d6a-9480-4496-b25f-49c84a18bc56")


    this.storeObs.subscribe(
      (data)=>
      {
        console.log("Got stores")
        this.stores = data.result;
        this.filterStores();
        //if(isNullOrUndefined(this.filteredStores) || this.filteredStores.length == 0)
        //{
        //  this.notAvailable = true;
        //  this.inspectletService.addTag("Service Not Available");
        //  this.submitting = false;
       // }
        //else
        //{
          this.router.navigate(['/main/pricing-results']);
          this.submitting = false;
        //}




      }
    )

  }

  filterStores() {
    this.filteredStores = this.stores;
    console.log("filtering stores")
    if (isNullOrUndefined(this.stores) || this.stores.length == 0) {
      return;
    }

    if(isNullOrUndefined(this.vehicle))
    {
      return;
    }

    var filtered = [];
    for (var i = 0; i < this.stores.length; i++) {
      var vehicleExclusions = this.stores[i].vehicleExclusions;
      if(isNullOrUndefined(vehicleExclusions) || vehicleExclusions.length == 0)
      {
        filtered.push(this.stores[i])
      }
      else
      {
        var add = true;
        for(var j = 0; j < vehicleExclusions.length; j++)
        {
          var vehicleExclusion = vehicleExclusions[j];
          if(vehicleExclusion.make.toLowerCase() === this.vehicle.make.toLowerCase() &&
            vehicleExclusion.model.toLowerCase() === this.vehicle.model.toLowerCase())
          {
            add = false;
            break;
          }
        }

        if(add)
        {
          filtered.push(this.stores[i])

        }
      }

    }

    this.filteredStores = filtered;
    this.anonymousPricingService.setStores(this.filteredStores);
  }


  storeChosen(item)
  {
    this.step = this.step + 1;
    this.storeId = item._id;
    this.selectedStore = item;
    console.log("Just set store id to: " + this.storeId)
    var itemsObs = this.sanityService.getProducts(item._id)
    itemsObs.subscribe(
      (items:any) =>
      {
        this.items = items.result[0].products;

      }
    )

  }



  reset() {
    this.step = 1;
    this.stores = [];
    this.items = [];
    this.data.zipCode = ""
    this.vehicle.make = "";
    this.vehicle.model = "";
  }

  tagZip()
  {
    var value = {
      "user entered zip code":this.data.zipCode
    }
    this.inspectletService.addTag(value)
  }

  tagVehicleYear()
  {
    var value = {
      "user selected year":this.vehicle.year
    }
    this.inspectletService.addTag(value)

  }

  tagVehicleMake()
  {
    var value = {
      "user selected make":this.vehicle.make
    }
    this.inspectletService.addTag(value)

  }

  tagVehicleModel()
  {
    var value = {
      "user selected model":this.vehicle.model
    }
    this.inspectletService.addTag(value)

  }

}
