import { Component, OnInit } from '@angular/core';
import {GoogleAnalyticsService} from '../google-analytics.service';

@Component({
  selector: 'app-google-analytics',
  templateUrl: './google-analytics.component.html',
  styleUrls: ['./google-analytics.component.css']
})
export class GoogleAnalyticsComponent implements OnInit {

  constructor(private googleAnalyticsService : GoogleAnalyticsService) { }


  defaultProduct = {
    'variant': {
      'sku':'111222333',
      'title':'This is a test product'
    },
    'name': "default product",
    'category': "category",
    'brand': "test",
    'price': 99.99,
    'quantity': 1,
  }

  defaultProductList = [this.defaultProduct]

  ngOnInit() {
  }

  captureClick()
  {
    var returnValue = this.googleAnalyticsService.emitEvent("user","click")
  }

  captureImpression()
  {
    var returnValue = this.googleAnalyticsService.addImpression(this.defaultProductList,"Test List")
  }

  capturePageView()
  {
    var returnValue = this.googleAnalyticsService.sendPageView()
  }

  captureAddProduct()
  {
    var returnValue = this.googleAnalyticsService.addToCart(this.defaultProduct)
  }

  captureRemoveProduct()
  {
    var returnValue = this.googleAnalyticsService.removeFromCart(this.defaultProduct)
  }

  capturePromotionClicked()
  {
    var returnValue = this.googleAnalyticsService.promotionClicked("test","test","test","top")
  }

  captureProcessTransaction()
  {
    var returnValue = this.googleAnalyticsService.sendTransaction(this.defaultProductList,"111")
  }

  captureRefundTransaction()
  {
    var returnValue = this.googleAnalyticsService.refund("111")
  }

  completeCheckoutStep(step, option)
  {
    var returnValue = this.googleAnalyticsService.completedCheckoutStep(step,option)
  }
}
