import {Component, OnInit} from '@angular/core';
import {UrlService} from "../url.service";
import {Observable} from "rxjs/index";
import {catchError, map} from "rxjs/internal/operators";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {FirebaseAuthService} from "../firebase-auth.service";
import {Router} from "@angular/router";
import {ErrorService} from "../error.service";
import {SuccessService} from "../success.service";
import {LocalStorageService} from "../local-storage.service";
import {LoginService} from "../login.service";

@Component({
  selector: 'app-password-reset',
  templateUrl: './password-reset.component.html',
  styleUrls: ['./password-reset.component.css']
})
export class PasswordResetComponent implements OnInit {


  username: string = "";
  password: string = "";
  firebaseUid: string = "";
  show: boolean = false;
  loading = false;
  complete = false;
  passwordResetModalVisible = false;

  constructor(private urlService: UrlService, private http: HttpClient, private firebaseAuth: FirebaseAuthService,
              private router: Router, private errorService: ErrorService,
              private successService: SuccessService, private localStorageService: LocalStorageService,
              private loginService: LoginService) {
    this.localStorageService.clear();
    this.handleFirebaseAuth.bind(this);
    console.log(urlService.getUrl("login"))
  }


  handleFirebaseTokenRetrieved(resp) {
    var user = this.firebaseAuth.getCurrentUser();
    var obs = this.loginService.login(user.email, resp, null);
    obs.subscribe((response) => console.log(response));

  }

  handleFirebaseAuth(resp) {
    var user = this.firebaseAuth.getCurrentUser();
    localStorage.setItem('firebaseUser', JSON.stringify(user));

    console.log(user.getIdToken(true).then((resp) => this.handleFirebaseTokenRetrieved(resp)).catch((resp) => this.handleError(resp)));
  }


  loginWithFacebook() {
    this.firebaseAuth.doFacebookLogin();
  }

  loginWithGoogle() {
    this.firebaseAuth.doGoogleLogin();
  }

  handleError(reason) {
    if (reason === "Error: The password is invalid or the user does not have a password.") {
      this.errorService.addErrors([{error: "Error Occurred", message: 'Username or password incorrect'}])
    } else {
      this.errorService.addErrors([{error: "Error Occurred", message: reason}])
    }
    console.log("Error: " + reason)
  }

  submit() {


    this.loading = true;
    var promise = this.firebaseAuth.sendPasswordReset(this.username);

    promise.then((resp) => {this.loading = false;this.complete = true;this.passwordResetModalVisible = true}).catch((resp) => {this.loading = false, this.complete = true;this.passwordResetModalVisible = true})
  }


  toggleShow() {
    this.show = !this.show;
  }


  ngOnInit() {
  }

}
