import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {environment} from "../../../environments/environment";
import {GoogleAnalyticsService} from "../../google-analytics.service";
import {Router} from "@angular/router";
import {InspectletService} from "../../inspectlet.service";

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.css']
})
export class NavComponent implements OnInit {

  @Input() showHamburgerMenu: boolean = false;
  @Output() hamburgerChange: EventEmitter<any> = new EventEmitter<any>();
  shopUrl: string;
  showMobileMenu: boolean = false;
  getStartedUrl: string;
  loginUrl: string;

  constructor(private googleAnalyticsService:GoogleAnalyticsService,
              private router: Router, private inspectletService: InspectletService) {
    this.shopUrl  = environment.shopUrl;
    this.getStartedUrl = this.shopUrl + "/main/get-started";
    this.loginUrl = this.shopUrl + "/main/login";

  }

  toggleMobileMenu()
  {
    this.showMobileMenu = !this.showMobileMenu;
  }

  toggleHamburgerMenu()
  {
    this.showHamburgerMenu = !this.showHamburgerMenu;
    this.hamburgerChange.emit(this.showHamburgerMenu);
  }


  ngOnInit() {
    this.googleAnalyticsService.promotionImpression('FIRST_TIME_DISCOUNT', 'First Time Discount Landing Page','','navbar-annoucement')

  }

  promotionClicked()
  {
    this.inspectletService.addTag("user clicked promotional banner")
    this.googleAnalyticsService.promotionClicked('FIRST_TIME_DISCOUNT', 'First Time Discount Landing Page','','navbar-annoucement')
    this.router.navigate(['/main/pricing-results']);

  }

}
