import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {environment} from "../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class SanityService {
  products: object[];


  profile:any;
  stateQuery:string = 'state.state == "active"';
  devStateQuery:string = '(state.state == "active" || state.state == "demo")';
  dataset:string = environment.sanityDataset
  constructor(private http: HttpClient) {
    console.log("Initializing sanity service")

  }


  getStateQuery()
  {

      return this.stateQuery;

  }

  getServiceLocationsByZip(storeLocationId)
  {
    var today = new Date();
    var dd = today.getDate();

    var mm = today.getMonth()+1;
    var yyyy = today.getFullYear();

    var dateString = yyyy + '-' + mm + '-' + dd;

    var query = '*[_type == "serviceLocation" %26%26 _id == "' + storeLocationId +'" %26%26 ' + this.getStateQuery() +']{ name, _id, email,imageUrl,products[]->[' + this.getStateQuery() + ']{_id,state,"variant":productVariant->,title,imageUrl,"discount":productVariant->discount,"productVariantDescription":productVariant->description,"price":productVariant->priceList[]->[' + this.getStateQuery() +' %26%26 activateDate <= "' + dateString + '"]|order(activateDate desc)[0].price,description,detailedDescription,name},"imageUrl":serviceProvider->imageUrl }';

    //var query = '*[_type == "serviceLocation" %26%26 ' + zip + ' in serviceProvider->zones %26%26 ' + zip + ' in zones %26%26 ' + this.getStateQuery() +']{ "name":serviceProvider->title, state,_id, email,address,phone, "vehicleExclusions":vehicleExclusion->vehicles[]->,"title":serviceProvider->title,"imageUrl":serviceProvider->imageUrl }';
    return     this.http
      .get(`https://pyjjsgec.apicdn.sanity.io/v1/data/query/${this.dataset}?query=${query}`)

  }

  getProducts(storeLocationId)
  {
    var today = new Date();
    var dd = today.getDate();

    var mm = today.getMonth()+1;
    var yyyy = today.getFullYear();

    var dateString = yyyy + '-' + mm + '-' + dd;
    //Will get the one price that is activate and has the closest activate date <= today
    var query = '*[_type == "serviceLocation" %26%26 _id == "' + storeLocationId +'" %26%26 ' + this.getStateQuery() +']{ name, _id, email,imageUrl,products[]->[' + this.getStateQuery() + ']{_id,state,"variant":productVariant->,title,imageUrl,"discount":productVariant->discount,"productVariantDescription":productVariant->description,"price":productVariant->priceList[]->[' + this.getStateQuery() +' %26%26 activateDate <= "' + dateString + '"]|order(activateDate desc)[0].price,description,detailedDescription,name} }';
    return     this.http
      .get(`https://pyjjsgec.apicdn.sanity.io/v1/data/query/${this.dataset}?query=${query}`)

  }

  loadConfig()
  {
    var query = '*[_type == "config"]{ key,value }';
    return     this.http
      .get(`https://pyjjsgec.apicdn.sanity.io/v1/data/query/${this.dataset}?query=${query}`)


  }

  ngOnInit(): void {
  }
}
