import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {environment} from "../../../environments/environment";
import {AnonymousPricingService} from "../../anonymous-pricing.service";
import {InspectletService} from "../../inspectlet.service";

@Component({
  selector: 'app-order-detail',
  templateUrl: './order-detail.component.html',
  styleUrls: ['./order-detail.component.css']
})
export class OrderDetailComponent implements OnInit {

  @Input() orderDetail:any = {};
  @Input() showCallToAction:boolean = false;

  @Output() onOrderDetailChosen: EventEmitter<any> = new EventEmitter<any>();
  @Output() onScheduleService: EventEmitter<any> = new EventEmitter<any>();

  shopUrl:any;
  getStartedUrl:any;
  loginUrl:any;

  vehicle:any;
  zipCode:any;

  constructor(private anonymousPricingService: AnonymousPricingService,
              private inspectletService: InspectletService) {
    this.shopUrl  = environment.shopUrl;
    this.getStartedUrl = this.shopUrl + "/main/create-account";
    this.loginUrl = this.shopUrl + "/main/login";

  }

  ngOnInit() {
    this.vehicle = this.anonymousPricingService.vehicle;
    this.zipCode = this.anonymousPricingService.zipCode;

  }

  orderDetailChosen(orderDetail)
  {
    this.onOrderDetailChosen.emit(orderDetail);

  }

  scheduleService(orderDetail)
  {
    this.onScheduleService.emit(orderDetail);
  }

  scheduleOilChangeClicked()
  {
    this.orderDetail.scheduled = true;
    this.inspectletService.addTag("user clicked SIGNUP after clicking Schedule this Oil Change")
    var url = this.getStartedUrl + "?item= " + this.orderDetail.item._id + "store= " + this.orderDetail.store._id + "vehicleYear=" + this.vehicle.year + "vehicleModel=" + this.vehicle.model + "vehicleMake=" + this.vehicle.make + "zipCode=" + this.zipCode
    window.open(url, "_blank");

    //this.orderDetail.scheduled = true
    //this.inspectletService.addTag("user clicked Schedule this Oil Change")
  }

  loginClicked()
  {
    this.orderDetail.scheduled = true;
    this.inspectletService.addTag("user clicked LOG IN after clicking Schedule this Oil Change")
    var url = this.loginUrl + "?item=" + this.orderDetail.item._id  + "store=" + this.orderDetail.store._id  + "zipCode=" + this.zipCode +  "vehicleYear=" + this.vehicle.year +  "vehicleModel=" + this.vehicle.model +  "vehicleMake=" + this.vehicle.make
    window.open(url, "_blank");

  }

  signupClicked()
  {
    this.orderDetail.scheduled = true;
    this.inspectletService.addTag("user clicked SIGNUP after clicking Schedule this Oil Change")
    var url = this.getStartedUrl + "?item= " + this.orderDetail.item._id + "store= " + this.orderDetail.store._id + "vehicleYear=" + this.vehicle.year + "vehicleModel=" + this.vehicle.model + "vehicleMake=" + this.vehicle.make + "zipCode=" + this.zipCode
    window.open(url, "_blank");


  }

}
